
.session_list1{
    z-index: 2;
    position: absolute;
    top: 5%;
    background-color: rgb(30, 30, 30);
    width: 97%;
    height: 90%;
    left: 3%;
    overflow: auto;
}


.session{
    z-index: 3;
    width: 90%;
    height: 2vw;
    background-color: rgb(30, 30, 30);
    /* background-color: rgb(238, 88, 88); */
    font-size:90%;
    color: aliceblue;
    border-radius: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

    padding-left: 5%;         /*  will grow the box by 10%, compensate by reducing width */

    /* margin-left: 10%; */

    /* text-indent: 10%; */

    /* cursor: context-menu; */
    cursor: pointer;
    /* margin: 0px 0px 0px 0px; top | right | bottom | left */

    /* margin-right: 7%; */

    white-space: nowrap;  /* Prevents wrapping */
    overflow: hidden;     /* Hides overflow */
    text-overflow: clip;  /* Clips the text without an ellipsis */

    /* justify-content: space-between; */
}

.session_option{
    z-index: 4;
    position: absolute  ;
    border-radius: 10px;
    height: 2vw;
    aspect-ratio: 1/1;
    /* background-color: aquamarine; */
    background-color:rgb(40, 40, 40);
    right: 12px;
    display: none;

}

/* .session:hover + .session_option{ */


.session_option_drop{
    z-index: 5;
    height: 4vw;
    /* aspect-rat   io: 1/1; */
    width: 6vw;
    position: absolute;
    background-color: rgb(40, 40, 40);
    /* bottom: -8.2vw;
    left: -6vw; */
    border-radius: 0.8vw;

    right: 12px;
    /* display: none; */
    transform: translateY(3.2vw);
}

.session_option_drop:active ~.session_option{
    display: block;
}

.session:hover .session_option{
    display: block;
}

.session:hover{
    background-color:rgb(40, 40, 40);
}


/* .session:hover .session_option_drop{
    display: block;
} */

.session_option_mask{
    z-index: 4;
    position: absolute  ;
    border-radius: 10px;
    height: 2vw;
    width: 14.8vw;

    /* aspect-ratio: 1/1; */
    /* background-color: aquamarine; */
    background-color:rgb(100, 100, 100);
    right: 0px;
    transform: translateY(-109%);
    opacity: 0.17;
    /* display: none; */
}



.session_dropdown{
    height: 2vw;
    width: 80%;
    padding-left: 20%;
    padding-top: 3%;
    font-size: 0.9vw;
    border-radius: 0.5vw;
    color: rgb(145, 145, 145);

    /* background-color: aquamarine; */
    /* border-radius: ; */
}

.session_dropdown:hover{
    /* background-color:rgb(45, 45, 45); */
    color: #ffffff;
}



.delete_session_confirm_box_mask{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0, 0, 0);
    opacity: 0.6;
    top: 0px;
    left: 0px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;  
    
    position: fixed;
}

.delete_session_confirm_box{
    /* position: absolute; */
    width: 25vw;
    height: 15vw;
    background-color: rgb(40, 40, 40);
    /* opacity: 0.4; */
    border-radius: 2vw;
    font-size: x-large;
    color: #ffffff;
}

.confirm_button{
    border-radius: 1vw;
    width: 6vw;
    height: 3vw;
    background-color:rgb(20, 20, 20);
    position: absolute;
    cursor: pointer;
    opacity: 0.7;

    /* transform: tra; */
}

.confirm_button:hover{
    opacity: 1;
    color: #ffffff;

}


.session_mask{
    z-index: 4;
    position: absolute  ;
    height: 2vw;
    aspect-ratio: 6.2/1;
    left: 0px;
}