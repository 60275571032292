
.code_container{
    /* position: relative; */
    background-color: rgb(8, 8, 8);
    /* transform: translate(0%,5%); */
    /* border: 1px solid #006305; */
}

.code_container_header{
    /* position: relative; */
    height: 1.6vw;
    background-color: rgb(49, 49, 49);
    /* transform: translate(0%, -0vw); */
    /* text-indent: 2%; */
    font-size: 1vw;

    
    display: flex;            /* Enables flexbox layout */
    align-items: center;      /* Vertically centers the child (if needed) */
    justify-content: left;    /* Horizontally centers the child */
}

.code_container_code{
    transform: translate(2%,-0.6vw);
    width: 98%;
    overflow-y: auto;
    /* background-color:rgb(255, 118, 118); */
    /* width: 100%; */
}

.code_copy_button{
    height: 90%;
    width: 10%;
    transform: translate(800%,0);
    /* background-color: aqua; */
    border-radius: 0.4vw;
    display: flex;            /* Enables flexbox layout */
    align-items: center;      /* Vertically centers the child (if needed) */
    justify-content: center;    /* Horizontally centers the child */
    cursor: pointer;
}
.code_copy_button:hover{
    /* background-color: rgb(66, 66, 66); */
    color: chartreuse;

}


.code_container_small{
    background-color: rgb(20, 20, 20);
    display: inline-block;
    border-radius: 10px;
    /* height: 1.8vw; */
    /* padding: 0 10px; Adds padding on the left and right */
    /* width: max-content; This will make the div only as wide as the text plus padding */
    padding: 2px 5px; /* Reduced padding */
    line-height: 1.2; /* Adjusted line height for tighter text spacing */
}


.code_container_smalll {
    background-color: rgb(8, 8, 8);
    display: flex;         /* Enables flexbox layout */
    flex-direction: row;   /* Stacks children horizontally */
    align-items: center;   /* Aligns children vertically at the center */
    overflow-x: auto;      /* Allows horizontal scrolling if content overflows */
    padding: 10px;         /* Adds some padding around the contents */
    gap: 10px;             /* Adds space between each child element */
    display: inline-block;
}