.chat{
    width: 100%;
    height: calc(98.5% - var(--textbox-height));

    /* background-color: rgb(87, 87, 87); */

    /* Takes up available space */
    flex-grow: 1; 
    box-sizing: border-box;
    /* max-width: 100%; */
    /* overflow-x: hidden; */
    /* overflow-y: auto; */

    /* display: flex;
    flex-direction: column; */

}

.chat_llm{
    /* background-color:  rgb(36, 36, 36); */
    width: 100%;
    /* height: 120%; */
    /* background-color: bisque; */

/*     
    display: flex;
    flex-direction: row;
    align-items: center; */


    margin: 10px 0;
    /* font-size: large; */
    /* color: rgb(174, 192, 192); */
    position: relative;
    justify-content: flex-start;
}

.chat_llm_text{
    /* background-color: rgb(36,36,36); */
    width: 96%;
    /* word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-all; */
    white-space: normal;
    /* height: 120%; */
    /* background-color: bisque; */

    /* display: flex; */
    
    line-height: 200%;
    /* flex-direction: row; */
    align-items: center;
    margin: 10px 2%;
    font-size: large;
    color: rgb(174, 192, 192);
    /* justify-content: flex-start; */
}




.chat_user{
    width: 100%;
    /* background-color: rgb(59,59,59); */
    /* background-color:  rgb(36, 36, 36); */
    display: flex;
    flex-direction: row;
    align-items: center;

    margin: 10px 0;
    /* font-size: x-large; */
    /* color: rgb(174, 192, 192); */
    position: relative;

    

    justify-content: flex-end;
}

.chat_user_text{
    border-radius: 1.4vh;
    width: 50%;
    padding: 10px;
    background-color: rgb(59,59,59);
    word-wrap: break-word;
    /* display: flex;
    flex-direction: row;
    align-items: center; */
    /* position: absolute; */
    margin: 30px 20px;
    font-size: large;
    color: rgb(174, 192, 192);

    border: 1px solid #ffffff;

    justify-content: flex-end;
}



.chat_bottom_pad{
    height: 20%;
    width: 100%;
    /* margin: 10px 0; */
    position: relative;
    /* background-color: aliceblue; */
}



