




.user_button{
    height: 80%;
    aspect-ratio: 1/1;
    background-color: rgb(3, 73, 12);
    right:0vw;
    border-radius: 50%;   
    transform: translate(-30%,30%);
    position: absolute;

    /* display: flex;
    align-items: center;
    justify-content: center; */

    cursor: pointer;
    
}

.user_button:hover{
    background-color: aqua;
}


.user_button2{
    height: 90%;
    aspect-ratio: 1/1;
    background-color: rgb(1, 0, 61);
    border-radius: 50%;  
    transform: translate(6%,5%);
    position: absolute;
}


.user_dropdown{
    /* height: 200%; */
    width:9%;
    /* aspect-ratio: 1/1; */
    right: 0%;
    transform: translate(-1vw,3.4vw);
    background-color: rgb(49, 49, 49);
    position: absolute;
    border-radius: 1vw;

}


.user_dropdown_option{
    height: 33.3%;
    aspect-ratio: 2.5/1;
    /* background-color: rgb(49, 49, 49); */
    border-radius: 1vw;
    
    cursor: pointer;
    color: rgb(170, 170, 170);

    display: flex;

}

.user_dropdown_option p {
    /* background-color: aqua; */
    
    font-size: 1.2vw;
    text-indent: 2.3vw;
    margin: 6% 0 0 0;    /* Remove margin */
    padding: 0;  
    /* Optionally remove padding if necessary */
  }

.user_dropdown_option:hover{
    /* background-color: rgb(39, 39, 39); */
    color: aliceblue;
}

.user_login_dialogue{
    position: absolute;
    width: 25vw;
    aspect-ratio: 1.5/1;
    background-color: rgb(50, 50, 50);
    transform: translate(37.5vw, 30vh);
    border-radius: 2vw;
}

.user_login_close_button{
    position: absolute;
    width: 3.4vw;
    aspect-ratio: 1/1;
    transform: translate(20vw, 2vh);
    border-radius: 1.7vw;
    
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(73, 3, 3);
    cursor: pointer;
}

.user_login_commit_button{
    position: absolute;
    width: 3.4vw;
    aspect-ratio: 1/1;
    transform: translate(15vw, 2vh);
    border-radius: 1.7vw;
    
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(3, 73, 12);
    cursor: pointer;
}

.button_inside{
    width: 3.0vw;
    aspect-ratio: 1/1;
    background-color: rgb(0, 0, 59);
    border-radius: 1.5vw;
}

.user_login_commit_button:hover{
    background-color: aqua;
}
.user_login_close_button:hover{
    background-color: rgb(255, 106, 106);
}




.user_name_input{
    position: relative;
    width:21vw;
    aspect-ratio: 10/1;
    background-color: rgb(80, 80, 80);
    transform: translate(1vw, 10vh);
    border-radius: 1vw;

    display: flex;
    align-items: center;
    justify-content: center;
    /* resize: none;
    border: none;
    outline: none;
    font-size: 1.7rem;
    color: aliceblue;    */

    /* padding: 0 1em ;  */
    /* overflow: hidden; */
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
}


.password_input{
    position: relative;
    width:21vw;
    aspect-ratio: 10/1;
    background-color: rgb(80, 80, 80);
    transform: translate(1vw, 12vh);
    border-radius: 1.5vw;

    border-radius: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.re_password_input{
    position: relative;
    width:21vw;
    aspect-ratio: 10/1;
    background-color: rgb(80, 80, 80);
    transform: translate(1vw, 14vh);
    border-radius: 1.5vw;

    border-radius: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
}



.input_field{
    position: relative;
    width:19vw;
    aspect-ratio: 10/1;
    background-color: transparent;

    resize: none;
    border: none;
    outline: none;
    font-size: 1.7rem;
    color: aliceblue;   

    overflow: hidden;
    white-space: nowrap;
}
