
.text_input{
    z-index: 3;
    /* border: 5px solid #ff0707; Light grey border */
    /* border-radius: 3vh; Rounded edges */

    /* background-color: rgb(255, 255, 255);     */
    /* height: calc(var(--dynamic-height)*0.1); */
    /* width: calc(var(--dynamic-height)*1.5); */
    /* height: 5vh; */
    height: var(--textbox-height);
    width:68%;
    /* position: relative; */
    /* opacity: 0.4; */
    bottom: 1.5vh;

    position: absolute;
    /* margin-bottom: 0%; */

    display: flex;            /* Enables flexbox layout */
    align-items: center;      /* Vertically centers the child (if needed) */
    justify-content: center;  /* Horizontally centers the child */


    position: fixed;
    
}

.text_input_child1{
    z-index: 3;
    
    /* border-radius: 2.5vh; Rounded edges */
    border-radius: 27px;
    background-color: rgb(59, 59, 59);
    /* height: 7vh; */
    height: 100%;

    width:97%;
    opacity: 0.3;
    /* bottom: %; */
    /* outline: 5vh; */
    /* border: yellow;
    border-width: 10px; */
    position: absolute;

    display: flex;            /* Enables flexbox layout */
    align-items: center;      /* Vertically centers the child (if needed) */
    justify-content: center;  /* Horizontally centers the child */
}

.text_input_child1:hover{
    opacity: 1;
}

.text_input_child2{
    z-index: 3;

    background-color:rgb(59, 59, 59);
    /* background-blend-mode:; */
    /* height: 7vh; */
    /* height: 3vh; */

    width:90%;
    opacity: 1;
    bottom: 0%;
    resize: none;
    border: none;
    outline: none;  /* Remove focus outline */
    /* font-size: xx-large; */
    font-size: x-large;
    position: absolute;

    

    color: rgb(255, 255, 255);
    /* position: absolute; */
    cursor:text; /* Add pointer cursor */
    /* display: flex; */
    /* margin-left: 5%; */
}



.send_button{
    z-index: 4;
    background-color: rgb(50, 50, 50);
    /* height: 5vh; */
    /* height: calc(3vh + 15px); */
    height: 54px;
    border-radius: 50%;
    aspect-ratio: 1/1;
    position: absolute;
    right: 0%;
    bottom: 0%;
}


.attachment_button{
    z-index: 4;
    background-color: rgb(50, 50, 50);
    /* height: 5vh; */
    /* height: calc(3vh + 15px); */
    height: 54px;
    border-radius: 50%;
    aspect-ratio: 1/1;
    position: absolute;
    left: 0%;
    bottom: 0%;
}

.attachment_button:hover{background-color: rgb(20, 20, 20);}
.send_button:hover{background-color: rgb(20, 20, 20);}
