body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}






/* .app {
  font-family: Arial, sans-serif;
} */


/* .sidebar {
  width: 250px;
  height: 900px;
  border: 1px solid #ccc;
  padding: 10px;
} */

/* .sidebar.collapsed {
  width: 50;
  overflow: hidden;
} */

/* .dropdown ul {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.dropdown li {
  padding: 5px;
  border-bottom: 1px solid #eee;
}

.dropdown li:last-child {
  border-bottom: none;
} */


