.general_button{
    /* z-index: 101; */
    /* background-color: blue; */
    color: rgb(182, 188, 194);
    
    display: flex;            /* Enables flexbox layout */
    align-items: center;      /* Vertically centers the child (if needed) */
    justify-content: center;  /* Horizontally centers the child */

    aspect-ratio: 1/1;
    /* border-radius: 50%; */
}


/* 
.general_button:hover{
    background-color: rgb(20, 20, 20);
} */
/* svg {
    fill: white; This sets the color of the SVG
} */
