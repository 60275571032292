
.header{
    z-index: 2;
    /* background-color: aqua; */
    /* height: calc(var(--dynamic-height)*0.1); */
    /* width: calc(var(--dynamic-height)*1.5); */
    height: 5vh;
    /* width: 100vw; */
    width: 100%;
    top: 0%;
    right: 0vw;
    position: absolute;
    /* opacity: 0.1; */


    position: fixed;
}


.new_chat_button1{
    z-index: 2;
    height: 100%;
    /* border-radius: 25%; */
    /* background-color: aqua; */
    aspect-ratio: 0.9/1;
    left: 13.2%;
    position: absolute;

    display: flex;
    align-items: center;
    /* justify-items: center; */
}

.new_chat_button2{
    z-index: 2;
    height: 90%;
    width: 100%;
    border-radius: 25%;
    position: absolute;

    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;

}

.new_chat_button3{
    z-index: 2;
    height: 50%;
    width: 50%;
    border-radius: 50%;
    background-color: rgb(0, 163, 8);
}

.new_chat_button2:hover{
    background-color: rgb(50, 50, 50);
}