html {
    height: 100%;  
    /* // Ensure the html element covers the entire viewport height */
    background: rgb(36, 36, 36);   
    /* // Set your desired background color or image */
}

body {
    margin: 0;  
    /* // Remove default margin */
    height: 100%;  
    /* // Ensure the body element also covers the entire viewport height */
    background: inherit;  
    /* // This will make the body inherit the background from the html element */
}



.main_layout{
    /* background-color: rgb(34, 34, 34); */
    /* height: var(--dynamic-height); */
    /* width: var(--dynamic-width); */
    /* width: var(--dynamic-widthFull); */
    height: 100vh;
    width:100lvw;
    position: absolute;
    z-index: 0;
    max-width: 100%;

    /* opacity:0.5; */

    /* background-color: rgb(36, 36, 36); */
    /* position: fixed; */
}

.workspace{
    z-index: 1;
    /* background-color: rgb(36, 36, 36); */
    /* height: calc(var(--dynamic-height)*0.1); */
    /* width: calc(var(--dynamic-height)*1.5); */
    height: 100vh;
    /* width: calc(calc(var(--dynamic-height)*0.3)); */
    width: 68vw;
    position: absolute; 
    /* opacity: 0.1; */
    right: 16vw;
    /* max-width: 100%; */


    display: flex;
    flex-direction: column;
}



.footer{
    z-index: 2;
    /* background-color: aqua; */
    /* height: calc(var(--dynamic-height)*0.1); */
    /* width: calc(var(--dynamic-height)*1.5); */
    height: 1.5vh;
    width: 68vw;
    /* position: relative; */
    /* opacity: 0.1; */
    bottom: 0%;
    right: 16vw;
    /* position: absolute; */
    /* margin-bottom: 0%; */
    color: aliceblue;
    font-size: xx-small;
    display: flex;            /* Enables flexbox layout */
    align-items: center;      /* Vertically centers the child (if needed) */
    justify-content: center;  /* Horizontally centers the child */

    position: fixed;
}

.r_sidebar{
    z-index: 1;
    background-color: rgb(30, 30, 30);
    /* height: calc(var(--dynamic-height)*0.1); */
    height: 100vh;
    /* width: calc(var(--dynamic-height)*.3); */
    width: 16vw;
    position: absolute;
    /* justify-content: left; */
    right: 0;

    position: fixed;
}


.l_sidebar{
    z-index: 1;
    background-color: rgb(30, 30, 30);
    /* height: calc(var(--dynamic-height)*0.1); */
    height: 100vh;
    /* width: calc(var(--dynamic-height)*.3); */
    width: 16vw;
    /* justify-content: right; */
    left: 0;
    position: absolute;

    position: fixed;
    
}

