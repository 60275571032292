.drop_down1{
    z-index: 3;
    position: absolute;
    height: 100%;
    width: 13.2vw;
    /* background-color: rgba(19, 248, 31, 0.788); */
    /* left: 2.5vw; */
    left: 0vw;
    top: 0%;
    display: flex;
    flex-direction: row;
    align-items: center;

    /* margin: 3%; */
}


.drop_down2{
    z-index: 3;
    height: 90%;
    width: 97%;
    /* background-color: rgb(40, 40, 40); */
    /* border-right-style: ridge; */
    border-radius: 10px;
    left: 2%;
    position: absolute;
    /* opacity: 0;5 */
    /* margin: 5% ; */

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    cursor: pointer;
}

.drop_down_2_icon{
    height: 100%;
    aspect-ratio: 0.7/1;
    /* background-color: bisque;    */
    position: absolute;
    left: 0%;
}


.drop_down2_text{
    white-space: nowrap;  /* Prevents wrapping */
    overflow: hidden;     /* Hides overflow */
    text-overflow: clip;  /* Clips the text without an ellipsis */

    width: 86%;
    /* background-color: blue; */
    position: absolute;
    left: 14%;
    font-size: larger;
    font-weight: 600;
    color: aliceblue;
    /* bottom:1px; */

}



/* .drop_down2:hover + .drop_down_float {
    opacity: 1;
} */

/* .drop_down2:hover {
    opacity: 1;
} */

.drop_down_float{
    z-index: 3;
    width: 95%;
    height: 600%;
    overflow: auto;
    background-color: rgb(40, 40, 40);
    position: absolute;
    top: 105%;
    left: 2.5%;
    border-radius: 10px;
    opacity: 1;
}



.model_name{
    width: 100%;
    height: 5vh;
    background-color: rgb(40, 40, 40);
    position: relative;
    border-radius: 10px;
    color: antiquewhite;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-size: large;

    cursor: pointer;
}

.model_name:hover {
    background-color: rgb(50, 50, 50);
}
